import React, { useEffect, useState, useCallback } from "react";
import cookie from "cookie";

import { Link } from "react-router-dom";

import api from "../api";
import { useForm } from "../hooks";

import Group from "../components/group";
import FormField from "../components/field";
import Button from "../components/button";
import Loading from "../components/loading";
import Switch from "../components/switch";

const formFields = {
  email: "",
  password: ""
};

const LoginScreen = ({ history }) => {
  const { formValues, handleInputChange } = useForm(formFields);
  const [remember, setRemember] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(
    history.location.state ? history.location.state.error : null
  );
  const { accessToken, userPayload } = cookie.parse(document.cookie);

  const loginUser = useCallback(
    async values => {
      try {
        setLoading(true);
        const response = await api.login({ ...values, remember });

        if (!response || !response.ok) {
          document.cookie = "userPayload=; Max-Age=-99999999;";
          document.cookie = "accessToken=; Max-Age=-99999999;";
          setError(response.error || "Error logging in. Please try again.");
        } else {
          return history.push("/account");
        }
      } catch (e) {
        setError("Login error");
        document.cookie = "userPayload=; Max-Age=-99999999;";
        document.cookie = "accessToken=; Max-Age=-99999999;";
      }

      setLoading(false);
    },
    [history, remember]
  );

  useEffect(() => {
    function checkTokens() {
      if (accessToken) return history.push("/account");

      if (userPayload) return loginUser();
    }

    checkTokens();
  }, [accessToken, history, loginUser, userPayload]);

  if (userPayload && loading) {
    return <Loading>Logging in…</Loading>;
  }

  return (
    <div className="screen vertical-center">
      <Group>
        {Object.keys(formFields).map(field => {
          return (
            <FormField key={field} name={field} fullWidth>
              <input
                autoFocus={field === "email"}
                id={field}
                type={field}
                value={formValues[field]}
                disabled={loading}
                onChange={ev => handleInputChange(field, ev.target.value)}
              />
            </FormField>
          );
        })}
        <FormField fullWidth label={false}>
          <Switch
            label="Save Credentials"
            checked={remember}
            onChange={ev => setRemember(ev.target.checked)}
          />
        </FormField>
      </Group>

      {error && (
        <div
          style={{
            textAlign: "center",
            paddingTop: 10,
            fontSize: 13,
            color: "red"
          }}
        >
          {error}
        </div>
      )}

      <Button
        className="submit"
        onClick={() => loginUser(formValues)}
        disabled={loading}
      >
        {loading ? (
          <Loading fill="#fff" horizontal>
            Logging in…
          </Loading>
        ) : (
          "Log in"
        )}
      </Button>

      <Link to="/" className="borderless-button">
        Back
      </Link>
    </div>
  );
};

export default LoginScreen;
