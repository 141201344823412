import React, { useState } from "react";

import { Link } from "react-router-dom";

import api from "../api";
import { useForm } from "../hooks";

import Loading from "../components/loading";
import Preview from "../components/preview";
import Group from "../components/group";
import FormField from "../components/field";
import Switch from "../components/switch";
import Button from "../components/button";

const FormScreen = ({ history }) => {
  const { ticket, imageUrl, backUrl = "/" } = history.location.state;

  const [generating, setGenerating] = useState(false);
  const { formValues, handleInputChange } = useForm(ticket);

  const onSubmit = async (values) => {
    setGenerating(true);

    let pass;
    try {
      pass = await api.issuePass(values);
      setGenerating(false);
    } catch (e) {
      return history.push("/", { message: e.message });
    }

    if (!pass || !pass.url) {
      return console.log("error");
    }

    setGenerating(false);
    window.location = pass.url;
  };

  return (
    <div className="screen">
      {imageUrl && <Preview src={imageUrl} />}

      <Group>
        {Object.keys(ticket).map((field) => {
          switch (field) {
            case "qr":
              return null;

            case "glasses":
              return (
                <FormField key={field} name={field} label={false} fullWidth>
                  <Switch
                    id={field}
                    checked={formValues[field]}
                    label="Glasses"
                    onChange={(ev) =>
                      handleInputChange(field, ev.target.checked)
                    }
                  />
                </FormField>
              );

            default:
              return (
                <FormField
                  key={field}
                  name={field}
                  fullWidth={["movie", "date"].includes(field)}
                >
                  <input
                    id={field}
                    type="text"
                    value={formValues[field]}
                    onChange={(ev) => handleInputChange(field, ev.target.value)}
                  />
                </FormField>
              );
          }
        })}
      </Group>

      <Button
        className="submit"
        onClick={() => onSubmit(formValues)}
        disabled={generating}
      >
        {generating ? (
          <Loading fill="#fff" horizontal>
            Generating ticket…
          </Loading>
        ) : (
          "Add to Apple Wallet"
        )}
      </Button>

      <Link to={backUrl} className="borderless-button">
        Back
      </Link>
    </div>
  );
};

export default FormScreen;
