import React, { useState } from "react";
import classnames from "classnames";

import styles from "./preview.module.css";

const Preview = ({ src }) => {
  const [overlayOpen, setOverlayOpen] = useState(false);

  return (
    <div
      className={classnames(styles.overlay, {
        [styles.overlayOpen]: overlayOpen
      })}
      onClick={() => setOverlayOpen(!overlayOpen)}
    >
      <img src={src} className={styles.preview} alt="Ticket" />
    </div>
  );
};

export default Preview;
