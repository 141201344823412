import QrScanner from "qr-scanner";
import { TesseractWorker, PSM, utils } from "tesseract.js";
import { mapValues, values, keys, zipObject, isFunction } from "lodash";
import { TICKET_FIELDS } from './ticket';

const ocr = new TesseractWorker();
const languagePreload = Promise.all([
    utils.loadLang({ langs: 'eng', langPath: ocr.options.langPath }),
    utils.loadLang({ langs: 'ces', langPath: ocr.options.langPath }),
]);

QrScanner.WORKER_PATH = "/qr-scanner-worker.min.js";

const loadImageAsCanvas = ( url, newWidth ) => {
    return new Promise( (resolve, reject) => {
        const img = new Image();
        img.onerror = reject;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const newHeight = img.naturalHeight * (newWidth / img.naturalWidth);
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage( img, 0, 0, newWidth, newHeight );
            resolve(canvas);
        };
        img.src = url;
    } );
};

const extractAreas = img => {
    console.log('extracting areas');
    return mapValues( TICKET_FIELDS, field => {
        const coords = [...field.coords];

        if (coords[1] < 0) {
          coords[1] = img.height + coords[1] - coords[3];
        }

        const canvas = document.createElement('canvas');
        canvas.width = coords[2];
        canvas.height = coords[3];
        const ctx = canvas.getContext('2d');
        ctx.drawImage( img, coords[0], coords[1], coords[2], coords[3], 0, 0, coords[2], coords[3] );
        return canvas;
    } );
};

const detectGlasses = img => {
    const target = [115, 180, 210];
    const imageData = img.getContext('2d').getImageData(0, 0, img.width, img.height);
    const data = imageData.data;
    const total = [0, 0, 0];
    const samplingInterval = 5;
    let count = 0;
    let i = 0;

    while ((i += samplingInterval * 4) < data.length) {
        count++;
        total[0] += data[i];
        total[1] += data[i + 1];
        total[2] += data[i + 2];
    }

    const diff = [
        Math.abs(target[0] - total[0] / count) / 255,
        Math.abs(target[1] - total[1] / count) / 255,
        Math.abs(target[2] - total[2] / count) / 255
    ];

    return diff[0] < 0.15 && diff[1] < 0.15 && diff[2] < 0.15;
};

const processArea = async ( area, id ) => {
    console.log('processing', id);
    const definition = TICKET_FIELDS[id];

    if ( definition.read ) {
        // eslint-disable-next-line
        switch ( definition.read ) {
            case 'qr':
                return QrScanner.scanImage(area);
            case 'number':
            case 'text':
                return new Promise( (resolve, reject) => {
                    ocr.recognize(area, definition.read === 'number' ? 'eng' : 'ces', {
                        tessedit_char_whitelist: definition.read === 'number' ? '0123456789' : undefined,
                        tessedit_pageseg_mode: PSM.PSM_RAW_LINE
                    })
                    .then(result => resolve(( result.text || '' ).trim()))
                    .catch( reject );
                } );
            case 'glasses':
                return detectGlasses( area );
        }
    }

    return true;
};

const formatIfNeeded = ( value, id ) => {
    const formatter = TICKET_FIELDS[id].format;
    if (isFunction(formatter)) {
        return formatter(value);
    }
    return value;
};

export const read = async ( url ) =>
    languagePreload.then( () => { 
        return loadImageAsCanvas(url, 480)
            .then( extractAreas )
            .then( async areas => {
                const processedAreas = mapValues( areas, processArea );
                const results = zipObject(keys(processedAreas), await Promise.all(values(processedAreas)));
                return mapValues( results, formatIfNeeded );
            } )
            .catch( err => console.log('reading', err) );
    } ).catch( err => console.log('preload', err) );
