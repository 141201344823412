import React from "react";
import classnames from "classnames";

import styles from "./button.module.css";

const Button = ({ className, children, ...props }) => {
  return (
    <button className={classnames(styles.button, className)} {...props}>
      {children}
    </button>
  );
};

export default Button;
