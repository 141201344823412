import React from "react";
import _ from "lodash";
import dayjs from "dayjs";

import cinemas from "../constants/cinemas";

import FormField from "./field";

import styles from "./booking.module.css";
import "dayjs/locale/cs";

dayjs.locale("cs");

const getSeats = tickets => tickets.map(ticket => ticket.seat).join(", ");
const getTypes = tickets => {
  if (tickets.length === 1) return tickets[0].type;

  const ticketTypes = _.groupBy(tickets, ticket => ticket.type);

  return Object.keys(ticketTypes)
    .map(type => `${ticketTypes[type].length}× ${type}`)
    .join(", ")
    .replace("Dospeli", "Dospělý");
};

const Booking = ({ onClick, booking }) => {
  const formattedDate = _.upperFirst(
    dayjs(booking.eventDate).format("dddd DD/MM/YYYY HH:mm")
  );
  const cinema = cinemas[booking.cinemaId].displayName;
  const hallName = Number(booking.venueName.toLowerCase().replace("sál ", ""));

  return (
    <FormField
      label={false}
      fullWidth
      onClick={() =>
        onClick({
          imageUrl: booking.eventPoster,
          ticket: {
            qr: booking.id,
            movie: booking.eventName,
            date: formattedDate,
            cinema,
            hall: Number.isNaN(hallName) ? booking.venueName : hallName,
            row: booking.tickets[0].row,
            seat: getSeats(booking.tickets),
            type: getTypes(booking.tickets),
            price: `${booking.totalAmount} Kč`,
            glasses: false
          }
        })
      }
    >
      <div className={styles.booking}>
        <img
          src={booking.eventPoster}
          className={styles.poster}
          alt={booking.eventName}
        />
        <div className={styles.bookingInfo}>
          <div className={styles.title}>{booking.eventName}</div>
          <div className={styles.venue}>{cinema}</div>
          <div className={styles.date}>{formattedDate}</div>

          <svg
            className={styles.arrow}
            viewBox="0 0 1024 1024"
            version="1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M320 257l65-65 319 320-319 320-65-65 255-255z" />
          </svg>
        </div>
      </div>
    </FormField>
  );
};

export default Booking;
