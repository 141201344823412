import React, { useEffect } from "react";

import { read } from "../reader";

import Loading from "../components/loading";

const ProcessScreen = ({ history }) => {
  const imageUrl = URL.createObjectURL(history.location.state.files[0]);

  useEffect(() => {
    async function processImage() {
      let ticket;
      try {
        ticket = await read(imageUrl);
      } catch (e) {
        return history.push("/", { message: e.message });
      }

      if (!ticket) {
        return history.push("/", {
          message: "Error processing the image. Please try again."
        });
      }

      history.push("/form", { ticket, imageUrl });
    }

    processImage();
    // eslint-disable-next-line
  }, []);

  return <Loading>Processing image…</Loading>;
};

export default ProcessScreen;
