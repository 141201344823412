export const TICKET_FIELDS = {
  qr: {
    read: 'qr',
    coords: [330, 197, 115, 115],
    key: 'bcm'
  },
  movie: {
    read: 'text',
    coords: [95, 95, 370, 30],
    key: 'p1v'
  },
  date: {
    read: 'text',
    coords: [15, 132, 370, 27],
    key: 's1v',
    format: date => {
      return date.charAt(0).toUpperCase() + date.slice(1)
    }
  },
  cinema: {
    read: 'text',
    coords: [15, 160, 370, 30],
    key: 'a1v'
  },
  hall: {
    read: 'text',
    coords: [165, 205, 160, 40],
    key: 's2v',
    format: hall => {
      if (hall.toLowerCase().indexOf('im') > -1) {
        return 'IMAX';
      }

      const endRegExp = /[0-9]+$/;
      const end = endRegExp.exec(hall);

      if (end) {
        return end[0];
      }

      return hall;
    },
  },
  row: {
    read: 'number',
    coords: [165, 245, 80, 40],
    key: 'h2v'
  },
  seat: {
    read: 'number',
    coords: [165, 285, 80, 40],
    key: 'h3v'
  },
  type: {
    read: 'text',
    coords: [15, 345, 150, 30],
    key: 'a2v',
    format: type => type.replace('32D', '3D').replace('Dospeli', 'Dospělý'),
  },
  price: {
    read: 'text',
    coords: [370, 345, 90, 30],
    key: 'a3v',
    format: price => {
      const result = /([0-9.]+)/gi.exec(price);

      if (result) {
        const int = parseInt(result, 10);

        if (!isNaN(int)) {
          return int + ' Kč';
        }
      }

      return price;
    },
  },
  glasses: {
    read: 'glasses',
    coords: [20, -20, 120, 40]
  }
};
