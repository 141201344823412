import React from "react";
import ReactDOM from "react-dom";
import { MemoryRouter, Switch, Route } from "react-router-dom";

import "./reader";
import "./global.css";

import ImportScreen from "./routes/import";
import LoginScreen from "./routes/login";
import AccountScreen from "./routes/account";
import ProcessScreen from "./routes/process";
import FormScreen from "./routes/form";

const container = document.getElementById("root");

ReactDOM.render(
  <MemoryRouter>
    <Switch>
      <Route path="/" exact component={ImportScreen} />
      <Route path="/login" exact component={LoginScreen} />
      <Route path="/account" exact component={AccountScreen} />
      <Route path="/process" exact component={ProcessScreen} />
      <Route path="/form" exact component={FormScreen} />
    </Switch>
  </MemoryRouter>,
  container
);

if (navigator.standalone) {
  container.classList.add("standalone");
}
