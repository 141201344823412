import React from "react";

import styles from "./switch.module.css";

const Switch = ({ label, ...props }) => {
  return (
    <label className={styles.switch}>
      <input type="checkbox" {...props} />
      {label} <i />
    </label>
  );
};

export default Switch;
