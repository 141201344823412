import React from "react";
import classnames from "classnames";

import styles from "./field.module.css";

const Field = ({ name, label = true, fullWidth, onClick, children }) => (
  <div
    className={classnames(styles.field, {
      [styles.full]: fullWidth
    })}
    onClick={onClick}
  >
    {label && (
      <label className={styles.label} htmlFor={name}>
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
    )}
    {children}
  </div>
);

export default Field;
