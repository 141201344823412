import _ from "lodash";

export const CINEMA_LIST = [
  {
    id: "1034",
    groupId: "brno",
    displayName: "Brno Olympia",
    link: "https://www.cinemacity.cz/cinemas/olympia",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:70140ff9-9e00-4c8e-be44-8b16457b6649",
    address: "U Dálnice 777, 664 42, Brno - Modřice",
    addressInfo: {
      address1: "U Dálnice 777",
      address2: null,
      address3: null,
      address4: null,
      city: "Brno - Modřice",
      state: null,
      postalCode: "664 42"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1034?key=CZOlympiaP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 49.13776,
    longitude: 16.633123
  },
  {
    id: "1037",
    groupId: "group-1037",
    displayName: "Fórum Liberec",
    link: "https://www.cinemacity.cz/cinemas/liberec",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:825a5619-9762-4953-b219-99cae2511d28",
    address: "Soukenné náměstí 2a/669, 460 01, Liberec",
    addressInfo: {
      address1: "Soukenné náměstí 2a/669",
      address2: null,
      address3: null,
      address4: null,
      city: "Liberec",
      state: null,
      postalCode: "460 01"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1037?key=CZLiberecP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 50.766273,
    longitude: 15.055446
  },
  {
    id: "1055",
    groupId: "group-1055",
    displayName: "Nová Karolína",
    link: "https://www.cinemacity.cz/cinemas/ostrava",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:9a174770-b609-4921-9ae2-7e971aa7e89c",
    address: "Jantarová 3344/4, 702 00, Ostrava",
    addressInfo: {
      address1: "Jantarová 3344/4",
      address2: null,
      address3: null,
      address4: null,
      city: "Ostrava",
      state: null,
      postalCode: "702 00"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1055?key=CZOstravaP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 49.831226,
    longitude: 18.288877
  },
  {
    id: "1053",
    groupId: "group-1053",
    displayName: "Atrium Pardubice",
    link: "https://www.cinemacity.cz/cinemas/pardubice",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:7ca9a5b5-b5f4-4826-becc-118f90b08bdb",
    address: "Atrium Palác Pardubice, Masarykovo nám 2799, 530 02, Pardubice",
    addressInfo: {
      address1: "Atrium Palác Pardubice",
      address2: "Masarykovo nám 2799",
      address3: null,
      address4: null,
      city: "Pardubice",
      state: null,
      postalCode: "530 02"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1053?key=CZPardubiceP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 50.037605,
    longitude: 15.7687845
  },
  {
    id: "1054",
    groupId: "group-1054",
    displayName: "Plzeň Plaza",
    link: "https://www.cinemacity.cz/cinemas/plzen",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:ce1c3e8a-f6db-4bf7-bb21-03cb2923936e",
    address: "Radčická 2, 1. patro - food court, 301 00, Plzeň",
    addressInfo: {
      address1: "Radčická 2",
      address2: "1. patro - food court",
      address3: null,
      address4: null,
      city: "Plzeň",
      state: null,
      postalCode: "301 00"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1054?key=CZPlzenP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 49.7493,
    longitude: 13.36891
  },
  {
    id: "1052",
    groupId: "prague",
    displayName: "Flora",
    link: "https://www.cinemacity.cz/cinemas/flora",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:bf77f2f9-e790-4e43-b00c-b2a00cd5bfea",
    address: "Vinohradská 151, 130 00, Praha 3 - Žižkov",
    addressInfo: {
      address1: "Vinohradská 151",
      address2: null,
      address3: null,
      address4: null,
      city: "Praha 3 - Žižkov",
      state: null,
      postalCode: "130 00"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1052?key=CZFloraP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 50.079,
    longitude: 14.461009
  },
  {
    id: "1050",
    groupId: "prague",
    displayName: "Galaxie",
    link: "https://www.cinemacity.cz/cinemas/galaxie",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:900f3ac9-5d2f-47ad-a6b6-b4d117cd8675",
    address: "Arkalycká 951/3, 149 00, Praha 4",
    addressInfo: {
      address1: "Arkalycká 951/3",
      address2: null,
      address3: null,
      address4: null,
      city: "Praha 4",
      state: null,
      postalCode: "149 00"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1050?key=CZGalaxieP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 50.032265,
    longitude: 14.527883
  },
  {
    id: "1056",
    groupId: "prague",
    displayName: "Centrum Chodov",
    link: "https://www.cinemacity.cz/cinemas/chodov",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:7e3a40d0-f4ee-4a17-962c-622bf50d191b",
    address: "Centrum Chodov, Roztylská 2321/19, 148 00, Prague 4",
    addressInfo: {
      address1: "Centrum Chodov",
      address2: "Roztylská 2321/19",
      address3: null,
      address4: null,
      city: "Prague 4",
      state: null,
      postalCode: "148 00"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1056?key=CZChodovP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 50.03207,
    longitude: 14.490996
  },
  {
    id: "1030",
    groupId: "prague",
    displayName: "OC Letňany",
    link: "https://www.cinemacity.cz/cinemas/letnany",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:bf570aae-ec11-46dc-ba71-f0abf34ad147",
    address: "Veselská 663, 199 00, Praha 9 - Letňany",
    addressInfo: {
      address1: "Veselská 663",
      address2: null,
      address3: null,
      address4: null,
      city: "Praha 9 - Letňany",
      state: null,
      postalCode: "199 00"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1030?key=CZLetnanyP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 50.13691,
    longitude: 14.502875
  },
  {
    id: "1031",
    groupId: "prague",
    displayName: "Nový Smíchov",
    link: "https://www.cinemacity.cz/cinemas/novysmichov",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:def48bfd-42af-49a8-a0e7-97d9ae93765e",
    address: "Plzeňská 8, 150 00, Praha 5",
    addressInfo: {
      address1: "Plzeňská 8",
      address2: null,
      address3: null,
      address4: null,
      city: "Praha 5",
      state: null,
      postalCode: "150 00"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1031?key=CZNovySmichovP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 50.074142,
    longitude: 14.40254
  },
  {
    id: "1033",
    groupId: "prague",
    displayName: "Slovanský dům",
    link: "https://www.cinemacity.cz/cinemas/slovanskydum",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:fc8efac6-ae68-4f8b-b566-c9cc06111687",
    address: "Na Příkopě 22, 110 00, Praha 1",
    addressInfo: {
      address1: "Na Příkopě 22",
      address2: null,
      address3: null,
      address4: null,
      city: "Praha 1",
      state: null,
      postalCode: "110 00"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1033?key=CZSlovanskyDumP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 50.086384,
    longitude: 14.4274025
  },
  {
    id: "1051",
    groupId: "prague",
    displayName: "Zličín",
    link: "https://www.cinemacity.cz/cinemas/zlicin",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:5501310d-1b62-4d17-b331-0435d1ab7b70",
    address: "Řevnická 121/1, 155 21, Praha 5",
    addressInfo: {
      address1: "Řevnická 121/1",
      address2: null,
      address3: null,
      address4: null,
      city: "Praha 5",
      state: null,
      postalCode: "155 21"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1051?key=CZZlicinP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 50.053925,
    longitude: 14.287215
  },
  {
    id: "1036",
    groupId: "group-1036",
    displayName: "Forum Ústí",
    link: "https://www.cinemacity.cz/cinemas/ustinadlabem",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:6b37a2ea-9a0f-4e33-87c5-b996624339ae",
    address: "Bílinská 6, 400 01, Ústí nad Labem",
    addressInfo: {
      address1: "Bílinská 6",
      address2: null,
      address3: null,
      address4: null,
      city: "Ústí nad Labem",
      state: null,
      postalCode: "400 01"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1036?key=CZUstiP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 50.658764,
    longitude: 14.038765
  },
  {
    id: "1035",
    groupId: "brno",
    displayName: "Velký Špalíček",
    link: "https://www.cinemacity.cz/cinemas/velkyspalicek",
    imageUrl:
      "https://www.cinemacity.cz/static/dam/jcr:421251d9-737e-4a8e-bbdd-03293060f9a9",
    address: "Mečová 2, 602 00, Brno",
    addressInfo: {
      address1: "Mečová 2",
      address2: null,
      address3: null,
      address4: null,
      city: "Brno",
      state: null,
      postalCode: "602 00"
    },
    bookingUrl: "https://sr.cinemacity.cz/S_CZ_1035?key=CZSpalicekP_RES",
    blockOnlineSales: false,
    blockOnlineSalesUntil: null,
    latitude: 49.193176,
    longitude: 16.607227
  }
];

export default _.keyBy(CINEMA_LIST, cinema => cinema.id);
