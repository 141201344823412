import React from "react";

import { Link } from "react-router-dom";

import Button from "../components/button";

import styles from "./import.module.css";

const ImportScreen = ({ history }) => {
  const { state } = history.location;

  const pickImage = ev => {
    history.push("/process", { files: ev.target.files });
  };

  return (
    <div className="screen centered">
      <label className={styles.button}>
        <input className={styles.input} type="file" onChange={pickImage} />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={styles.icon}
          viewBox="0 0 512 512"
          width="120"
          height="120"
          fillOpacity="0.15"
        >
          <path d="M262 199c-1-3-5-3-7-1l-92 123c-3 3-1 7 3 7l161 14c4 1 6-3 5-7l-70-136zm105 65c-1-3-5-3-8-1l-24 26a5 5 0 0 0-1 5l26 49c0 2 2 3 3 3l45 4c4 0 7-4 5-7l-46-79zm11-40c11 0 21-8 23-19 3-15-8-28-24-28-11 0-20 8-23 19-2 15 9 29 24 28zM455 129l-66-5-6-67c-1-15-14-26-29-25L57 59c-15 1-26 14-25 29l27 297c1 15 14 26 29 24l16-1-1 17c-2 15 9 28 24 29l298 26c15 1 28-10 29-25l26-296c1-15-10-28-25-30zM88 301c-4 0-7-3-8-6L62 95c0-4 3-7 6-8l279-25c4 0 7 3 8 6l5 53-202-18c-15-1-28 10-29 25l-15 170-26 3zm362-136l-18 200c0 4-3 7-7 6l-19-1-260-23c-4 0-7-4-7-7l4-44 14-156c0-4 4-7 7-7l198 18 30 2 52 5c3 0 6 3 6 7z" />
        </svg>

        <Button>Select Image</Button>
      </label>

      {state && state.message && (
        <small className={styles.error}>{state.message}</small>
      )}

      <Link to="/account" className="borderless-button">
        Log in
      </Link>
    </div>
  );
};

export default ImportScreen;
