import React from "react";
import classnames from "classnames";

import styles from "./loading.module.css";

const Loading = ({ size = 24, horizontal, fill, children }) => {
  return (
    <div
      className={classnames(styles.loading, {
        [styles.horizontal]: horizontal
      })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 12"
        width="24"
        height="24"
        className={styles.spinner}
        style={{ stroke: fill }}
      >
        <g>
          <path d="M6 3V0.5" />
          <path opacity=".5" d="M6 11.5v-2.5" />
          <path opacity=".92" d="M4.5 3.4l-1.25-2.16" />
          <path opacity=".42" d="M8.75 10.76l-1.25-2.16" />
          <path opacity=".83" d="M3.4 4.5l-2.16-1.25" />
          <path opacity=".33" d="M10.76 8.75l-2.16-1.25" />
          <path opacity=".75" d="M3 6H0.5" />
          <path opacity=".25" d="M11.5 6h-2.5" />
          <path opacity=".67" d="M3.4 7.5l-2.16 1.25" />
          <path opacity=".17" d="M10.76 3.25l-2.16 1.25" />
          <path opacity=".58" d="M4.5 8.6l-1.25 2.16" />
          <path opacity=".08" d="M8.75 1.24l-1.25 2.16" />
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            keyTimes="0;0.08333;0.16667;0.25;0.33333;0.41667;0.5;0.58333;0.66667;0.75;0.83333;0.91667"
            values="0 6 6;30 6 6;60 6 6;90 6 6;120 6 6;150 6 6;180 6 6;210 6 6;240 6 6;270 6 6;300 6 6;330 6 6"
            dur="0.83333s"
            begin="0s"
            repeatCount="indefinite"
            calcMode="discrete"
          />
        </g>
      </svg>
      <span className={styles.text}>{children}</span>
    </div>
  );
};

export default Loading;
