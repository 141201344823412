import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";

import api from "../api";

import Group from "../components/group";
import Booking from "../components/booking";
import Loading from "../components/loading";
import Button from "../components/button";

import styles from "./account.module.css";

const AccountScreen = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const hasAccessToken = document.cookie.includes("accessToken=");

  const onLogoutClick = () => {
    document.cookie = "userPayload=; Max-Age=-99999999;";
    doLogout();
  };

  const doLogout = useCallback(
    error => {
      document.cookie = "accessToken=; Max-Age=-99999999;";
      history.push("/login", { error });
    },
    [history]
  );

  useEffect(() => {
    if (!hasAccessToken) {
      return history.push("/login");
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.getBookings();

        if (!response || !response.ok) throw new Error();

        setBookings(response.data);
        setLoading(false);
      } catch (e) {
        doLogout();
      }
    };

    fetchData();
  }, [hasAccessToken, history, doLogout]);

  if (!hasAccessToken) return null;

  if (loading) {
    return <Loading>Loading bookings…</Loading>;
  }

  return (
    <div className={classnames("screen", styles.overflow)}>
      <div className={classnames("nav", styles.header)}>
        <h1>Account</h1>
        <Button onClick={onLogoutClick} className={styles.logout}>
          Logout
        </Button>
      </div>

      <div className={styles.list}>
        {bookings.length ? (
          <Group>
            {bookings.map(booking => (
              <Booking
                key={booking.id}
                booking={booking}
                onClick={booking =>
                  history.push("/form", { ...booking, backUrl: "/account" })
                }
              />
            ))}
          </Group>
        ) : (
          <div className={styles.error}>
            <h2>No current bookings</h2>
            <p>Go buy some!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountScreen;
