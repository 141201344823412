import { useState } from "react";

export function useForm(initialState) {
  const [formValues, setFormValues] = useState(initialState);

  return {
    formValues,
    handleInputChange(id, value) {
      setFormValues(state => ({ ...state, [id]: value }));
    }
  };
}
