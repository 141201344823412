import mock from "./__api-mock__";

const USE_MOCK_API = false;

const apiRequest = async (url, options = {}, asBlob = false) => {
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  });

  if (asBlob) {
    return response.blob();
  }

  return response.json();
};

const getApi = () => {
  if (USE_MOCK_API) {
    return mock;
  }

  return {
    issuePass: (data) =>
      apiRequest(
        "/api/issue-pass",
        {
          method: "POST",
          body: JSON.stringify(data),
        },
        true
      ).then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "ticket.pkpass";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      }),
    login: (user) =>
      apiRequest("/api/login", {
        method: "POST",
        body: JSON.stringify(user),
      }),
    getBookings: () => apiRequest("/api/bookings"),
  };
};

export default getApi();
